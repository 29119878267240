import {Controller} from "@hotwired/stimulus"
import {get} from "@rails/request.js"

export default class extends Controller {
  static values = {
    url: String,
    page: Number,
  };

  initialize() {
    this.scroll = this.scroll.bind(this);
    this.pageValue = this.pageValue || 2;
    this.fetching = false
  }

  connect() {
    document.addEventListener("scroll", this.scroll);
  }

  scroll() {
    if (this.scrollReachedEnd && !this.hasLastPageTarget && !this.fetching) {
      this.fetching = true
      this._fetchNewPage().then(() => {
        this.fetching = false
      })
    }
  }

  async _fetchNewPage() {
    const url = new URL(this.urlValue);
    url.searchParams.set('page', this.pageValue + 1)

    await get(url.toString(), {
      responseKind: 'turbo-stream'
    });

    this.pageValue +=1;
  }

  get scrollReachedEnd() {
    const {scrollHeight, scrollTop, clientHeight} = document.documentElement;
    const distanceFromBottom = scrollHeight - scrollTop - clientHeight;
    return distanceFromBottom < 20;
  }

  get hasLastPageTarget() {
    return document.querySelectorAll('[data-last-page]').length > 0;
  }
}
